// src/components/ShinyEmbed.js
import React, { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

const ShinyApp = () => {
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    // Assume user is authenticated via Amplify Auth.
    // fetch() request to backend to get the token:
    const fetchToken = async () => {
      try {
        // If you’re calling a REST endpoint directly, you need the Cognito ID token to attach in the Authorization header:
        const session = await fetchAuthSession();
        console.log("id token", session.tokens.idToken)
        console.log("access token", session.tokens.accessToken)
        const idToken = session.tokens.idToken;
        const response = await fetch('https://kgpyd1bnn1.execute-api.us-east-2.amazonaws.com/default/fe-ShinyDashboardGenerateAuthToken', {
          headers: { Authorization: idToken }
        });

        
        if (!response.ok) {
          throw new Error('Failed to retrieve token');
        }

        const data = await response.json();
        setIframeSrc(`https://www.fedashboard.com/?auth_token=${encodeURIComponent(data.token)}`);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <h2>Staff Metrics Dashboard</h2>
      {iframeSrc ? (
        <iframe
          title="Shiny App"
          src={iframeSrc}
          width="100%"
          height="1000px"
          style={{ border: 'none' }}
          allowFullScreen
        />
      ) : (
        <p>Loading your secure dashboard...</p>
      )}
    </div>
  );
};

export default ShinyApp;

