// src/S3FileEditor.js
import React, { useState, useEffect } from 'react';
import { uploadData, downloadData } from 'aws-amplify/storage';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Papa from 'papaparse';
import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

function S3FileEditor() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [nextId, setNextId] = useState(0);
  const fileName = 'staff_names.csv'; // Replace with your CSV file name

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const operation = downloadData({ path: fileName });
        const { body } = await operation.result;
        const text = await body.text();
        const results = Papa.parse(text, { header: true });
        let data = results.data;

        // Filter out empty rows
        data = data.filter((row) =>
          Object.values(row).some((value) => value !== null && value !== '')
        );

        if (data && data.length > 0) {
          // Extract headers (column names), excluding any empty headers
          const headers = results.meta.fields.filter((field) => field && field.trim() !== '');

          setColumns([
            ...headers.map((header) => ({
              field: header,
              headerName: header,
              flex: 1, // Use flex to adjust column widths
              editable: true, // Set to true to make all fields editable
            })),
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                      icon={<SaveIcon />}
                      label="Save"
                      onClick={handleSaveClick(id)}
                    />,
                    <GridActionsCellItem
                      icon={<CancelIcon />}
                      label="Cancel"
                      onClick={handleCancelClick(id)}
                      color="inherit"
                    />,
                  ];
                }

                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={handleEditClick(id)}
                    color="inherit"
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                  />,
                ];
              },
            },
          ]);

          // Set the data with unique IDs
          const dataWithId = data.map((row, index) => ({ id: index, ...row }));
          setRows(dataWithId);
          setNextId(dataWithId.length); // Initialize nextId
        } else {
          setColumns([]);
          setRows([]);
        }
      } catch (err) {
        console.error('Error fetching file:', err);
      }
      setLoading(false);
    };

    fetchData();
  }, [fileName]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.View } }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    // If the row is new and cancelled, remove it from the rows
    const isNewRow = id >= nextId;
    if (isNewRow) {
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    }
  };

  const handleDeleteClick = (id) => () => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  };

  const handleAddRow = () => {
    const newId = nextId;
    setNextId((prevId) => prevId + 1);

    const emptyRow = { id: newId };
    columns.forEach((column) => {
      if (column.field !== 'actions') {
        emptyRow[column.field] = '';
      }
    });

    setRows((prevRows) => [...prevRows, emptyRow]);

    // Set the new row into edit mode
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [newId]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
    }));
  };

  const saveFile = async () => {
    setLoading(true);
    try {
      // Remove the 'id' field before converting back to CSV
      const rowsToSave = rows.map(({ id, ...rest }) => rest);

      const csv = Papa.unparse(rowsToSave);
      const blob = new Blob([csv], { type: 'text/csv' });
      const operation = uploadData({
        path: fileName,
        data: blob,
      });
      await operation.result;
      alert('File saved successfully!');
    } catch (err) {
      console.error('Error saving file:', err);
      alert('Error saving file. See console for details.');
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <h3>Edit CSV File in S3</h3>
        <p>
          To edit data, click on a cell and make your changes. Click "Save Changes to S3" button to
          save.
        </p>
      </Box>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              disableExtendRowFullWidth={false} // Allow the row to extend full width
            />
          </Box>

          <Box
            sx={{
              flexShrink: 0,
              py: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#fafafa',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddRow}>
              Add New Row
            </Button>
            <Button variant="contained" color="primary" onClick={saveFile}>
              Save Changes to S3
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default S3FileEditor;
