import React, { useState, useEffect } from 'react';
import { uploadData, downloadData } from 'aws-amplify/storage';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Autocomplete } from '@mui/material';

const GoalsEditor = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState({ start_date: '', duration_days: '', title: '', description: '' });
  const [newClientName, setNewClientName] = useState('');
  const [isAddClientDialogOpen, setIsAddClientDialogOpen] = useState(false);

  useEffect(() => {
    // Fetch client list from S3 bucket CSV file
    const fetchClients = async () => {
      try {
        console.log('Fetching allowed clients from S3 CSV...');
        const fileName = 'FE - Client - Client Lead.csv';
        // Initiate the download operation
        const operation = downloadData({ path: fileName });
        // Wait for the download to complete
        const { body } = await operation.result;
        // Read the content as text
        const csvText = await body.text();

        // Parse CSV to extract client names
        const clientNames = csvText.split('\n').slice(1).map((line) => {
          const columns = line.split(',');
          return columns[0]; // Extract only the client column
        }).filter((name) => name);
        console.log('Extracted client names:', clientNames);
        setClients(clientNames);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
  }, []);

  const handleClientChange = async (event) => {
    const client = event.target.value;
    setSelectedClient(client);

    try {
      const key = `goals/${client}/${client}_goals.json`;
      const operation = downloadData({ path: key });
      const { body } = await operation.result;
      const json = await body.text();
      setGoals(JSON.parse(json).goals);
    } catch (error) {
      console.error('Error fetching client goals:', error);
      setGoals([]);
    }
  };

  const handleGoalChange = (e) => {
    const { name, value } = e.target;
    setNewGoal({ ...newGoal, [name]: value });
  };

  const handleEditGoalChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGoals = [...goals];
    updatedGoals[index] = { ...updatedGoals[index], [name]: value };
    setGoals(updatedGoals);
  };

  const handleDeleteGoal = (index) => {
    const updatedGoals = goals.filter((_, i) => i !== index);
    setGoals(updatedGoals);
  };

  const handleAddGoal = async () => {
    if (selectedClient) {
      const newGoalId = goals.length > 0 ? goals[goals.length - 1].goal_id + 1 : 1;
      const updatedGoals = [
        ...goals,
        { goal_id: newGoalId, ...newGoal }
      ];
      setGoals(updatedGoals);

      const updatedClientGoals = {
        client: selectedClient,
        goals: updatedGoals
      };

      try {
        const key = `goals/${selectedClient}/${selectedClient}_goals.json`;
        const blob = new Blob([JSON.stringify(updatedClientGoals)], { type: 'application/json' });
        const operation = uploadData({ path: key, data: blob });
        await operation.result;
        alert('Goal added successfully!');
      } catch (error) {
        console.error('Error saving goals:', error);
      }
    }
  };

  const handleSaveGoals = async () => {
    if (selectedClient) {
      const updatedClientGoals = {
        client: selectedClient,
        goals: goals
      };

      try {
        const key = `goals/${selectedClient}/${selectedClient}_goals.json`;
        const blob = new Blob([JSON.stringify(updatedClientGoals)], { type: 'application/json' });
        const operation = uploadData({ path: key, data: blob });
        await operation.result;
        alert('Goals saved successfully!');
      } catch (error) {
        console.error('Error saving goals:', error);
      }
    }
  };

  const handleAddClient = async () => {
    if (newClientName) {
      setClients([...clients, newClientName]);
      setSelectedClient(newClientName);
      setGoals([]);
      setIsAddClientDialogOpen(false);

      const newClientGoals = {
        client: newClientName,
        goals: []
      };

      try {
        const key = `goals/${newClientName}/${newClientName}_goals.json`;
        const blob = new Blob([JSON.stringify(newClientGoals)], { type: 'application/json' });
        const operation = uploadData({ path: key, data: blob });
        await operation.result;
        alert('Client added successfully!');
      } catch (error) {
        console.error('Error saving new client:', error);
      }
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Client</InputLabel>
        <Select value={selectedClient} onChange={handleClientChange} label="Select Client">
          {clients.map(client => (
            <MenuItem key={client} value={client}>{client}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={() => setIsAddClientDialogOpen(true)} sx={{ mb: 2 }}>Add New Client</Button>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Goals for {selectedClient}</Typography>
        {goals.map((goal, index) => (
          <Box key={goal.goal_id} sx={{ mb: 4 }}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={goal.title}
              onChange={(e) => handleEditGoalChange(index, e)}
              sx={{ mb: 1 }}
            />
            <TextField fullWidth label="Start Date" placeholder="YYYY-MM-DD" name="start_date" value={goal.start_date}
              onChange={(e) => handleEditGoalChange(index, e)}
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              label="Duration (days)"
              name="duration_days"
              value={goal.duration_days}
              onChange={(e) => handleEditGoalChange(index, e)}
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={goal.description}
              onChange={(e) => handleEditGoalChange(index, e)}
              sx={{ mb: 1 }}
            />
            <Button variant="contained" color="secondary" onClick={() => { if (window.confirm('Are you sure you want to delete this goal?')) handleDeleteGoal(index); }} sx={{ mt: 1 }}>Delete Goal</Button>
          </Box>
        ))}
        <Button variant="contained" color="primary" onClick={handleSaveGoals} sx={{ mt: 2 }}>Save Goals</Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Add New Goal</Typography>
        <TextField fullWidth label="Start Date" placeholder="YYYY-MM-DD" name="start_date" value={newGoal.start_date}
          onChange={handleGoalChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Duration (days)"
          name="duration_days"
          value={newGoal.duration_days}
          onChange={handleGoalChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={newGoal.title}
          onChange={handleGoalChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={newGoal.description}
          onChange={handleGoalChange}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddGoal}>Add Goal</Button>
      </Box>

      <Dialog open={isAddClientDialogOpen} onClose={() => setIsAddClientDialogOpen(false)}>
        <DialogTitle>Add New Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the new client.
          </DialogContentText>
          <Autocomplete
            options={clients}
            value={newClientName}
            onChange={(event, newValue) => setNewClientName(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin="dense"
                label="Client Name"
                type="text"
                fullWidth
                variant="standard"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddClientDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddClient}>Add Client</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GoalsEditor;