// src/App.js
import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider, useTheme, View, Heading, Text, Button, Image, useAuthenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import S3FileEditor from './S3FileEditor';
// import EmailEditor from './EmailEditor';
// import CallEditor from './CallEditor';
import './App.css'; // If you have any CSS files
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AthenaCallEditor from './AthenaCallEditor';
import AthenaEmailEditor from './AthenaEmailEditor';
import GoalsEditor from './GoalsEditor';
import ShinyApp from './ShinyApp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import logo from './assets/logo.png';

import { Home } from "./Home";
import { Login } from "./Login";
import "./styles.css";

Amplify.configure(awsExports);

// Styled Box for DataGrid with alternating colors and improved readability
const StyledDataGridBox = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& .MuiDataGrid-row:nth-of-type(even)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '& .MuiDataGrid-cell': {
    padding: '8px',
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const components = {
  Header() {
    const { tokens } = useTheme();
    const logoPath = require('./assets/logo.png');

    return (
      <View
        textAlign="center"
        padding={tokens.space.large}
        style={{
          background: 'linear-gradient(45deg, #42a5f5, #1976d2)',
          color: '#fff', backgroundColor: '#f0f0f0',
        }}
      >
        <Image
          alt="FE logo"
          src={logoPath} loading="lazy"
          style={{ height: '40px', marginBottom: '8px' }}
        />
        <Heading level={3} color="#fff">FocusedEnergy Dashboard</Heading>
      </View>
    );
  }
};

function App() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider>
      <Authenticator components={components}>
      {({ signOut, user }) => (
          <Container maxWidth={selectedTab === 1 ? false : "lg"} sx={{ backgroundColor: '#f7f7f7', padding: '16px', borderRadius: '8px' }}>
            <Box sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
              {/* Header Section */}
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  background: 'linear-gradient(45deg, #42a5f5, #1976d2)',
                  color: '#fff',
                  position: 'relative',
                  mb: 4,
                  borderRadius: '8px',
                }}
              >
                <Box sx={{ position: 'absolute', left: '20px', top: '20px' }}>
                  <Image
                    alt="FE logo"
                    src={logo}
                    sx={{ height: '30px' }}
                  />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h4" gutterBottom>
                    FocusedEnergy Dashboard
                  </Typography>
                </Box>
                <Box sx={{ position: 'absolute', right: '20px', top: '20px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={signOut}
                  >
                    Sign Out
                  </Button>
                </Box>
              </Paper>

              {/* Tab Navigation */}
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                variant="fullWidth"
                sx={{ mb: 2 }}
              >
                <Tab label="Data Editor" />
                <Tab label="Dashboard" />
              </Tabs>

              {/* Data Editor Tab */}
              {selectedTab === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Staff Names and Departments</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledDataGridBox>
                          <S3FileEditor />
                        </StyledDataGridBox>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Calls</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledDataGridBox>
                          <AthenaCallEditor />
                        </StyledDataGridBox>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Emails</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledDataGridBox>
                          <AthenaEmailEditor />
                        </StyledDataGridBox>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>

                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Goals</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <StyledDataGridBox>
                          <GoalsEditor />
                        </StyledDataGridBox>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )}

              {/* Dashboard Tab */}
              {selectedTab === 1 && (
                <Box sx={{ mt: 4, width: '100%', height: '80vh' }}>
                  <ShinyApp />
                </Box>
              )}
            </Box>
          </Container>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
